import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortableText from 'react-portable-text';

import { Grid, Typography } from '@material-ui/core';

export const CtaSection = ({ header, body, blurbs }) => {
	// const theme = useTheme();
	return (
		<Grid container direction='column'>
			<Grid item>
				<PortableText
					content={header}
					serializers={{
						h2: ({ children }) => (
							<Typography
								variant='h2'
								color='primary'
								style={{
									marginBottom: '2rem',
									fontWeight: 700,
									lineHeight: 1.2,
								}}>
								{children}
							</Typography>
						),
					}}
				/>
			</Grid>

			{blurbs.map((blurb, index) => (
				<Grid
					item
					key={index}
					container
					direction='row'
					alignItems='flex-start'
					style={{ marginBottom: '2rem', flexWrap: 'nowrap' }}>
					<FontAwesomeIcon
						icon={['fas', blurb.icon]}
						size='2x'
						style={{
							color: blurb.accentColor.hexValue ?? '#002D5C',
							// height: '40px',
							// width: '40px',
							// border: `1px solid #E5EAF4`,
							// borderRadius: '8px',
							// background: '#E5EAF4',
							// padding: '8px',
							textAlign: 'center',
							marginRight: '20px',
						}}
					/>
					<Typography
						variant='h6'
						color='primary'
						style={{ fontFamily: 'Roboto', fontWeight: 400 }}>
						{blurb.title}
					</Typography>
				</Grid>
			))}
			<Grid item>
				<Typography
					variant='h5'
					color='primary'
					style={{
						// marginTop: '2rem',
						fontWeight: 600,
						// fontFamily: 'Roboto',
					}}>
					{body}
				</Typography>
			</Grid>
		</Grid>
	);
};
